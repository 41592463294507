import GlobalStyle from 'components/GlobalStyle';
import type { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles';

function MyApp({ Component, pageProps }: AppProps<{ session: Session }>) {
  return (
    <SessionProvider session={pageProps.session}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Component {...pageProps} />
        <Toaster position="top-right" reverseOrder={false} gutter={8} />
      </ThemeProvider>
    </SessionProvider>
  );
}

export default MyApp;
