import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    primary: '#1E1E1E',
    primaryLight: '#282828',
    secondary: '#0070f3',
    background: '#1E1E1E',
    black: '#000',
    white: '#FFF',
    gray: '#e2e8f0',
    aqua: '#33FFFF',
    lemon: '#BAE132',
    cherry: '#FF4C47',
    yellow: '#FCFC12',
  },
  border: {
    radius: '10px',
  },
  font: {
    family: 'Nunito, sans-serif',
    size: 14,
  },
};
